//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7F4Q0oeTfJX9uGMPSoUNxQFpXljgcYDQ",
  authDomain: "video-269a7.firebaseapp.com",
  projectId: "video-269a7",
  storageBucket: "video-269a7.firebasestorage.app",
  messagingSenderId: "914343423289",
  appId: "1:914343423289:web:7ca7a9b57f56b50c7a0ce8",
  measurementId: "G-DXL7JH0D48",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;

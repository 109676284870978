import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { collection, setDoc, updateDoc, deleteDoc, doc, getDocs, getDoc } from "firebase/firestore"; 
import Spinner from './Spinner';
import axios from 'axios';

const AdminYoutubeTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [taskData, setTaskData] = useState({
    title: '',
    bonus: 0,
    id: '',
    link: '',
    duration: 0 // New field for duration
  });
  const [showTaskInputs, setShowTaskInputs] = useState(false);
  const [showFetchModal, setShowFetchModal] = useState(false); // Show/hide fetch modal
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [numVideos, setNumVideos] = useState(10); // Set default number of videos to fetch
  const [youtubeApiKey, setYoutubeApiKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage] = useState(10); // Number of tasks to display per page

  const DEFAULT_ICON_URL = 'https://gamausdt.app/prem.svg'; // Default icon URL

  useEffect(() => {
    fetchYoutubeApiKey(); // Fetch the YouTube API key
    fetchTasks();
  }, []);

  const fetchYoutubeApiKey = async () => {
    const apiKeyDoc = await getDoc(doc(db, 'settings', 'SJJZZljohD4OaWrDQsNm'));
    if (apiKeyDoc.exists()) {
      const data = apiKeyDoc.data();
      setYoutubeApiKey(data.youtubeapi); // Set the API key from Firestore
    } else {
      console.error('No API key document found!');
    }
  };

  const fetchTasks = async () => {
    const querySnapshot = await getDocs(collection(db, "youtubeTasks"));
    const tasksList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTasks(tasksList);
    setLoading(false);
  };

  const fetchCounter = async () => {
    const counterDoc = await getDoc(doc(db, "counters", "taskCounter4"));
    if (counterDoc.exists()) {
      return counterDoc.data().currentId;
    } else {
      await setDoc(doc(db, "counters", "taskCounter4"), { currentId: 0 });
      return 0;
    }
  };

  const incrementCounter = async () => {
    const currentId = await fetchCounter();
    const newId = currentId + 1;
    await setDoc(doc(db, "counters", "taskCounter4"), { currentId: newId });
    return newId;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData({
      ...taskData,
      [name]: name === 'bonus' || name === 'duration' ? Number(value) : value
    });
  };

  const extractYoutubeID = (url) => {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/|youtube.com\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : '';
  };

  const handleAddTask = async () => {
    try {
      const newId = await incrementCounter();
      const videoId = extractYoutubeID(taskData.link);
      const taskDoc = doc(db, "youtubeTasks", newId.toString());
      await setDoc(taskDoc, { 
        ...taskData, 
        id: newId, 
        link: videoId, 
        icon: DEFAULT_ICON_URL // Set default icon URL
      });
      setSuccessMessage('Task successfully added!');
      setShowTaskInputs(false);
      setTaskData({
        title: '',
        bonus: 0,
        id: '',
        link: '',
        duration: 0 // Reset duration field
      });
      fetchTasks();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleEditTask = (task) => {
    setTaskData(task);
    setShowTaskInputs(true);
    setIsEditing(true);
    setCurrentTaskId(task.id);
  };

  const handleUpdateTask = async () => {
    const videoId = extractYoutubeID(taskData.link);
    const taskDoc = doc(db, "youtubeTasks", currentTaskId.toString());
    try {
      await updateDoc(taskDoc, {
        title: taskData.title,
        bonus: taskData.bonus,
        link: videoId, 
        duration: taskData.duration 
      });
      setSuccessMessage('Task successfully updated!');
      setShowTaskInputs(false);
      setTaskData({
        title: '',
        bonus: 0,
        id: '',
        link: '',
        duration: 0
      });
      setIsEditing(false);
      setCurrentTaskId('');
      fetchTasks();
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  const handleDeleteTask = async (id) => {
    try {
      const taskDoc = doc(db, "youtubeTasks", id.toString());
      await deleteDoc(taskDoc);
      fetchTasks();
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  const cancelEdits = () => {
    setIsEditing(false);
    setShowTaskInputs(!showTaskInputs);
    setTaskData({
      title: '',
      bonus: 0,
      id: '',
      link: '',
      duration: 0 
    });
  };

  // Fetch videos from YouTube based on keyword
  const fetchVideosFromYouTube = async () => {
    if (!keyword) {
      setSuccessMessage('Please enter a keyword.');
      return;
    }
    
    setLoading(true);
    try {
      const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
        params: {
          part: 'snippet',
          q: keyword,
          maxResults: numVideos,
          key: youtubeApiKey,
          type: 'video', // Only fetch video results
        }
      });

      const videos = response.data.items.map(video => ({
        title: video.snippet.title,
        link: video.id.videoId, // Use videoId as the link
        duration: 10, // Set default duration to 10 seconds
        bonus: 100, // Set default bonus to 100 points
        icon: DEFAULT_ICON_URL // Default icon URL
      }));

      // Save each video to Firestore
      for (const video of videos) {
        const taskDoc = doc(db, "youtubeTasks", video.link);
        await setDoc(taskDoc, video);
      }

      setSuccessMessage(`${videos.length} videos successfully added!`);
      fetchTasks(); // Refresh tasks after adding
    } catch (error) {
      console.error("Error fetching videos: ", error);
      setSuccessMessage('Failed to fetch videos.');
    } finally {
      setLoading(false);
      setShowFetchModal(false); // Close fetch modal
    }
  };

  // Pagination logic
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);
  const totalPages = Math.ceil(tasks.length / tasksPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div id='refer' className="w-full flex flex-col h-[100vh] scroller overflow-y-auto pt-4 pb-[150px]">
          <div className='w-full flex flex-col space-y-4'>
            <div className='w-fit'>
              <button onClick={() => setShowTaskInputs(!showTaskInputs)} className={`${showTaskInputs ? 'hidden' : 'block'} bg-[#f5bb5f] font-semibold text-[15px] rounded-[6px] w-fit px-4 py-3 text-[#000] mb-4`}>
                {showTaskInputs ? 'Cancel' : 'New task'}
              </button>
              <button onClick={() => setShowFetchModal(true)} className="bg-[#f5bb5f] font-semibold text-[15px] rounded-[6px] w-fit px-4 py-3 text-[#000] mb-4">
                Fetch Videos from YouTube
              </button>
            </div>

            {showTaskInputs && (
              <>
                <div className="flex w-full flex-wrap gap-3">
                  <div className='flex flex-col w-full sm:w-[49%] gap-1'>
                    <label className='text-[13px] pl-1 pb-[2px] font-medium'>Task title</label>
                    <input
                      type="text"
                      name="title"
                      value={taskData.title}
                      onChange={handleInputChange}
                      placeholder="Title"
                      className="bg-[#4b4b4b] w-full placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
                    />
                  </div>

                  <div className='flex flex-col w-full sm:w-[49%] gap-1'>
                    <label className='text-[13px] pl-1 pb-[2px] font-medium'>Task bonus amount</label>
                    <input
                      type="number"
                      name="bonus"
                      value={taskData.bonus}
                      onChange={handleInputChange}
                      placeholder="Bonus"
                      className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
                    />
                  </div>

                  <div className='flex flex-col w-full sm:w-[49%] gap-1'>
                    <label className='text-[13px] pl-1 pb-[2px] font-medium'>Task link</label>
                    <input
                      type="text"
                      name="link"
                      value={taskData.link}
                      onChange={handleInputChange}
                      placeholder="Link"
                      className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
                    />
                  </div>

                  {/* Duration Input */}
                  <div className='flex flex-col w-full sm:w-[49%] gap-1'>
                    <label className='text-[13px] pl-1 pb-[2px] font-medium'>Task Duration (seconds)</label>
                    <input
                      type="number"
                      name="duration"
                      value={taskData.duration}
                      onChange={handleInputChange}
                      placeholder="Duration"
                      className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  {isEditing ? (
                    <>
                      <button onClick={handleUpdateTask} className="bg-green-500 font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Update Task</button>
                      <button onClick={cancelEdits} className="bg-[#4a3a3a] font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={handleAddTask} className="bg-[#f5bb5f] font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#000]">New task</button>
                      <button onClick={cancelEdits} className="bg-[#4a3a3a] font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Cancel</button>
                    </>
                  )}
                </div>
              </>
            )}

            {successMessage && (
              <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
                <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto p-6">
                  <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-end items-center pb-3">
                      <div className="modal-close cursor-pointer z-50" onClick={() => setSuccessMessage('')}>
                        <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <p>{successMessage}</p>
                    </div>
                    <div className="flex justify-center pt-2">
                      <button className="modal-close bg-blue-500 text-white p-2 rounded" onClick={() => setSuccessMessage('')}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Search Input */}
            <div className="flex w-full mb-4">
              <input
                type="text"
                placeholder="Search tasks..."
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="bg-gray-100 w-full p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Tasks List */}
            <div className='flex flex-wrap justify-between gap-4 w-full'>
              {currentTasks.map(task => (
                <div key={task.id} className="p-4 rounded-[10px] bg-cards w-full sm:w-[49%] flex flex-col space-y-4">
                  <span className='flex items-start gap-2 font-medium'>
                    <span className=''>
                      <img src={DEFAULT_ICON_URL} alt={task.title} className='w-[25px]' />
                    </span>
                    <span className='flex flex-col'>
                      <p>Title: {task.title}</p>
                      <p>Bonus: {task.bonus}</p>
                    </span>
                  </span>
                  <div className='flex items-center justify-start text-[13px] gap-3'>
                    <button onClick={() => handleEditTask(task)} className="bg-green-500 rounded-[6px] text-white px-2 py-[6px]">Edit</button>
                    <button onClick={() => handleDeleteTask(task.id)} className="bg-red-500 rounded-[6px] text-white px-2 py-[6px]">Delete {task.id}</button>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Fetch Videos Modal */}
      {showFetchModal && (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto p-6">
            <h2 className="text-lg font-semibold mb-4">Fetch YouTube Videos</h2>
            <input
              type="text"
              placeholder="Enter keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="bg-gray-100 w-full p-2 mb-4 border border-gray-300 rounded"
            />
            <select
              value={numVideos}
              onChange={(e) => setNumVideos(e.target.value)}
              className="bg-black text-white w-full p-2 mb-4 border border-gray-300 rounded"
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
            <button
              onClick={fetchVideosFromYouTube}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Fetch Videos
            </button>
            <button
              onClick={() => setShowFetchModal(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded mt-4"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminYoutubeTasks;
